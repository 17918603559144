<template>
  <div class="left-box">
    <div class="f f-j-c f-a-c">
      <div class="left-title-box">
        <span class="left-title">监控</span>
      </div>
    </div>
    <div class="all-box">
      <div class="f f-a-c">
        <div class="tag" />
        <div class="all-text">播放所有</div>
      </div>
    </div>
    <div class="m-fgx" />
    <div class="all-change f">
      <template v-for="(item, index) in allTab">
        <div
          :key="index"
          :class="`all-item f f-j-c f-a-c ${
            selectedBG === index ? 'selected-bg' : 'default-bg'
          }`"
          @click="tabChange(index)"
        >
          <span class="all-item-text">{{ item }}</span>
        </div>
      </template>
    </div>
    <div class="monitor-box">
      <el-scrollbar class="el-scrollbar">
        <template v-for="(item, index) in monitorList">
          <div :key="index" :class="index > 0 ? 'magrin-top-35' : ''">
            <div class="monitor-box-item" @click="onFold(index)">
              <div class="m-l-35 f f-a-c f-j-s-b">
                <div class="f f-a-c">
                  <div class="tag" />
                  <div
                    :class="`all-text ${
                      titleIndex === index ? 'item-selected-text' : ''
                    }`"
                  >
                    {{ item.name }}
                  </div>
                </div>
                <div class="m-r-19 f f-a-c">
                  <i
                    :class="`arrow-icon ${
                      item.selectArrow
                        ? 'el-icon-arrow-down'
                        : 'el-icon-arrow-up'
                    }`"
                  />
                </div>
              </div>
              <div class="m-fgx" />
              <div v-show="!item.selectArrow">
                <template v-if="item.devices && item.devices.length > 0">
                  <template v-for="(d, ind) in item.devices">
                    <div
                      :key="ind"
                      :class="`item-content f f-a-c ${
                        d.choose ? 'item-selected-bg' : ''
                      }`"
                      @click.stop="slectItem(index, ind)"
                    >
                      <el-image
                        :class="`${d.choose ? 'image-wh' : 'camera-imges'}`"
                        :src="`${
                          d.choose
                            ? require('../../../assets/image/camera-selected.png')
                            : require('../../../assets/image/camera-select.png')
                        }`"
                        :fit="'contain'"
                      />
                      <span
                        :class="`${
                          d.choose ? 'item-selected-text' : ''
                        } item-content-text`"
                      >{{ d.name }}</span>
                    </div>
                  </template>
                </template>
                <template v-else>
                  <div class="not-have-warning f f-j-c f-a-c">
                    暂无视频监控数据!
                  </div></template>
              </div>
            </div>
          </div>
        </template>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {
    videoList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      selectedBG: 0,
      titleIndex: 0,
      allTab: ['2X2', '3X3', '全屏'],
      monitorList: []
    }
  },
  computed: {},
  watch: {
    videoList: {
      handler(nVal, oVal) {
        this.monitorList = nVal.map((item, index) => {
          return {
            selectArrow: index > 0,
            id: item.id,
            name: item.name,
            devices: item.devices.map((items) => {
              return {
                choose: false,
                accessToken: items.accessToken,
                channelNo: items.channelNo,
                deviceId: items.deviceId,
                hdUrl: items.hdUrl,
                name: items.name,
                sn: items.sn,
                status: items.status,
                statusTxt: items.statusTxt,
                url: items.url
              }
            })
          }
        })
      },
      deep: true
    }
  },
  methods: {
    /**
     * 切换监控显示模式
     * @param {Number} index 索引
     */
    tabChange(index) {
      this.selectedBG = index
      this.$emit('tabChange', index)
    },
    /**
     * 监控列表折叠事件
     * @param {Number} index 索引
     */
    onFold(index) {
      const _item = this.monitorList[index]
      _item.selectArrow = !_item.selectArrow
      this.titleIndex = _item.selectArrow ? this.titleIndex : index
      this.clearAllSelect()
      this.$emit('onFold', { data: _item, index: index })
    },
    /**
     * 选择监控
     * @param {Number} index 监控索引
     * @param {Number} index 监控列表索引
     */
    slectItem(index, ind) {
      const _items = this.monitorList[index].devices[ind]
      if (_items.choose) return
      _items.choose = true
      this.selectedBG = 2
      this.clearLastSelcet(_items)
      this.$emit('slectItem', { data: _items, index: ind })
    },
    /**
     * 清除列表中选中的-不区分模块
     */
    clearAllSelect() {
      this.monitorList.forEach((m) => {
        m.devices.forEach((a) => {
          a.choose = false
        })
      })
    },
    /**
     * 清除上一次选中的-区分模块
     */
    clearLastSelcet(_items) {
      this.monitorList.forEach((j) => {
        j.devices.forEach((d) => {
          if (d.deviceId !== _items.deviceId) {
            d.choose = false
          }
        })
      })
    }
  }
}
</script>

<style lang='scss' scoped>
::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
}
.el-scrollbar {
  height: 100%;
}
.selected-bg {
  background: rgba(34, 104, 164, 1) !important;
}
.default-bg {
  background: rgba(227, 234, 241, 0.21) !important;
}
.left-box {
  width: 336px;
  height: calc(100vh - 40px);
  margin: 19px 0 19px 12px;
  background: rgba(11, 38, 92, 0.04);
  border: 1px solid rgba(15, 128, 205, 1);
  box-shadow: 0 0 15px #666666 inset;
  opacity: 0.8;
  .left-title-box {
    width: 143px;
    height: 0px;
    border-top: 41px solid #2268a4;
    border-right: 25px solid transparent;
    border-left: 25px solid transparent;
    position: relative;
    .left-title {
      position: absolute;
      top: -41px;
      height: 40px;
      width: 143px;
      font-size: 22px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      line-height: 40px;
      text-align: center;
    }
  }
  .all-box {
    margin-top: 38px;
    padding-left: 35px;
  }
  .all-change {
    margin-top: 24px;
    padding-left: 46px;
    .all-item {
      width: 79px;
      height: 35px;
      margin-left: 10px;
      cursor: pointer;
      .all-item-text {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.8);
      }
    }
  }
  .monitor-box {
    margin-top: 40px !important;
    height: calc(100vh - 263px);
    .monitor-box-item {
      cursor: pointer;
      .m-l-35 {
        margin-left: 35px;
      }
      .m-r-19 {
        margin-right: 19px;
      }
      .arrow-icon {
        font-size: 22px;
        font-family: SimSun;
        font-weight: 400;
        color: #41a5d8;
      }
    }
    .item-content {
      cursor: pointer;
      margin-top: 1px;
      min-height: 60px;
      .camera-imges {
        margin-left: 48px;
        width: 21px;
        height: 15px;
      }
      .item-content-text {
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #41a5d8;
        margin-left: 5px;
      }
    }
  }
}
.image-wh {
  margin-left: 48px !important;
  width: 21px !important;
  height: 19px !important;
}
.tag {
  width: 6px;
  height: 21px;
  background: #28a3f0;
  border-radius: 3px;
}
.all-text {
  font-size: 26px;
  font-family: SimHei;
  font-weight: 400;
  color: #ffffff;
  opacity: 0.8;
  margin-left: 10px;
}
.m-fgx {
  height: 1px;
  background: #e3eaf1;
  opacity: 0.1;
  margin-top: 11px;
}
.magrin-top-35 {
  margin-top: 35px !important;
}
.item-selected-bg {
  background: rgba(234, 238, 241, 0.08) !important;
}
.item-selected-text {
  color: #ffc066 !important;
}
.not-have-warning {
  height: 100px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.6);
}
</style>
