<template>
  <div class="content-box f">
    <!-- 监控左侧区域 -->
    <Left
      ref="leftBox"
      :video-list="videoList"
      @tabChange="tabChange"
      @onFold="onFold"
      @slectItem="slectItem"
    />
    <!-- 监控右侧区域 -->
    <div class="f f-c">
      <div class="box-right">
        <div class="right-title">
          当前位置: {{ currentLocation.join(" > ") }}
        </div>
        <div class="video-content">
          <el-row :span="24">
            <template v-for="(item, index) in playVideos">
              <el-col :key="index" :span="colNum">
                <div
                  :class="`video-item ${pattern !== 2 ? 'm-r-15' : ''}`"
                  :style="`height:${boxHeight}px`"
                >
                  <div :id="`player_${item.deviceId}`" class="myPlayer-video" />
                  <!-- 底部操作条 -->
                  <div class="video-footer f f-a-c f-j-s-b">
                    <el-tooltip
                      :visible-arrow="true"
                      effect="dark"
                      :content="item.name"
                      placement="bottom"
                    >
                      <div
                        :class="`footer-title color-41a5d8 c-d text-ellipsis ${
                          pattern === 0
                            ? 'm-w-325'
                            : pattern === 1
                              ? ' f-s-14 m-w-110'
                              : ''
                        }`"
                      >
                        {{ item.name }}
                      </div>
                    </el-tooltip>
                    <div class="f f-a-c">
                      <div
                        :class="`footer-two f f-a-c color-41a5d8 ${
                          pattern === 1 ? 'f-s-12' : ''
                        }`"
                      >
                        <div
                          :class="`footer-delayed c-p ${
                            item.delayState ? 'color-FB8A4C' : ''
                          }`"
                          @click.stop="onDelayed(index)"
                        >
                          延时播放
                        </div>
                        <div
                          :class="`footer-playback m-l-15 c-p ${
                            item.backState ? 'color-FB8A4C' : ''
                          }`"
                          @click.stop="onPlayBack(index)"
                        >
                          回放
                        </div>
                        <div
                          class="footer-hd m-l-15 c-p"
                          @click.stop="onHd(index)"
                        >
                          <span
                            :class="`${!item.hdState ? 'color-FB8A4C' : ''}`"
                          >标</span>/<span
                            :class="`hd-color ${
                              item.hdState ? 'color-FB8A4C' : ''
                            }`"
                          >高清</span>
                        </div>
                      </div>
                      <div class="footer-three f f-a-c">
                        <el-tooltip
                          :visible-arrow="true"
                          effect="dark"
                          :content="item.micState ? '结束说话' : '开始说话'"
                          placement="bottom"
                        >
                          <el-image
                            :class="`mic-image c-p ${
                              item.micState ? 'mic-image-ed' : ''
                            }`"
                            :src="`${
                              item.micState
                                ? require('../../assets/image/miced.png')
                                : require('../../assets/image/mic.png')
                            }`"
                            :fit="'contain'"
                            @click.stop="handleClick('mic', index)"
                          />
                        </el-tooltip>
                        <el-tooltip
                          :visible-arrow="true"
                          effect="dark"
                          :content="item.recording ? '结束录制' : '开始录制'"
                          placement="bottom"
                        >
                          <el-image
                            :class="`record-image m-l-10 c-p`"
                            :src="`${
                              item.recording
                                ? require('../../assets/image/Recordinged.png')
                                : require('../../assets/image/Recording.png')
                            }`"
                            :fit="'contain'"
                            @click.stop="handleClick('recording', index)"
                          />
                        </el-tooltip>
                        <el-tooltip
                          :visible-arrow="true"
                          effect="dark"
                          :content="item.horn ? '关闭声音' : '开启声音'"
                          placement="bottom"
                        >
                          <el-image
                            :class="`horn-image m-l-10 c-p`"
                            :src="`${
                              item.horn
                                ? require('../../assets/image/horned.png')
                                : require('../../assets/image/horn.png')
                            }`"
                            :fit="'contain'"
                            @click.stop="handleClick('horn', index)"
                          />
                        </el-tooltip>
                        <el-tooltip
                          :visible-arrow="true"
                          effect="dark"
                          :content="'截屏'"
                          placement="bottom"
                        >
                          <el-image
                            :class="`screenshot-image m-l-10 c-p`"
                            :src="`${require('../../assets/image/screenshot.png')}`"
                            :fit="'contain'"
                            @click.stop="handleClick('screenshot', index)"
                          />
                        </el-tooltip>
                        <el-tooltip
                          :visible-arrow="true"
                          effect="dark"
                          :content="'全屏播放'"
                          placement="bottom"
                        >
                          <el-image
                            :class="`full-image m-l-10 c-p`"
                            :src="`${require('../../assets/image/full.png')}`"
                            :fit="'contain'"
                            @click.stop="handleClick('full', index)"
                          />
                        </el-tooltip>
                      </div>
                    </div>
                  </div>
                  <!-- 视频录制动画 -->
                  <div v-show="item.recording" class="reco-load">
                    <loading-recoding />
                  </div>
                </div>
              </el-col>
            </template>
          </el-row>
        </div>
      </div>
      <div class="pagination-box f f-a-c f-j-c">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="pageTotal"
          :current-page.sync="currentPage"
          :hide-on-single-page="false"
          @current-change="currentChange"
        />
      </div>
    </div>
    <!-- 延时播放和回放对话框 -->
    <el-dialog
      :title="dialogVisibleTitle"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="40%"
      @close="close"
    >
      <div class="player-container">
        <!-- 回放 -->
        <template v-if="status">
          <div class="date-picker">
            <el-date-picker
              v-model="dateVal"
              :clearable="false"
              style="width: 60%"
              type="datetime"
              :picker-options="pickerOptions"
              placeholder="请选择开始回放的日期和时间"
              value-format="timestamp"
              @change="dateChange"
            />
          </div>
          <div v-if="dialogVisible" class="video-back f f-j-c f-a-c">
            <div v-if="dateVal" id="bakc-video-box" class="bakc-video" />
            <div v-else class="t-s">请选择开始回放的日期和时间!</div>
          </div>
        </template>
        <!-- 延时播放 https://media.vued.vanthink.cn/sparkle_your_name_am720p.mp4-->
        <template v-else>
          <vue-core-video-player v-if="dialogVisible" :src="videoUrl" />
        </template>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
    <loading v-if="loading" />
    <!-- 返回按钮 -->
    <!-- <back /> -->
    <!-- 右下角浮层 -->
    <!-- <float :homeShow="true" :equipmentShow="true"></float> -->
  </div>
</template>

<script>
import back from '@/components/back'
import screenfull from 'screenfull'
import Loading from '@/components/loading'
import loadingRecoding from '@/components/loading-recoding'
import { getVideo, getDdelayedVideoUri, getVideoPlayBack } from '@/api/monitor'
import { paginationFun, hasVal } from '../../utils/index'
import Left from './components/left'
// import float from "@/components/float";
export default {
  components: {
    // float,
    back,
    Loading,
    loadingRecoding,
    Left
  },
  data() {
    return {
      currentLocation: [],
      loading: false,
      colNum: 12, // 监控容器宽度12为(2x2),8为(3x3),24(宽度占满)
      boxHeight: 0, // 监控容器高度
      pattern: 0, // 监控显示模式(0为2x2,1为3x3,2为全屏)
      pats: 2, // 行数(2x2为2行,3x3为3行,全屏为1行)
      pages: {
        pageNo: 1, // 页数
        pageSize: 4 // 条数
      },
      currentPage: 1, // 当前页数
      pageTotal: 0, // 总页数
      videoList: [], // 全部监控数据(全部模块)
      originalVideo: [], // 分页前-原始监控数据
      playVideos: [], // 分页后-监控数据
      isFullScreen: false, // 左侧全屏状态
      player: [],
      stopRefresh: false,
      dialogVisibleTitle: undefined,
      dialogVisible: false,
      videoUrl: '',
      dateVal: null,
      pickerOptions: {
        disabledDate(time) {
          // disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
          return time.getTime() > Date.now() // 选当前时间之前的时间
          // return time.getTime() < Date.now()//选当前时间之后的时间
        }
      },
      status: false,
      backVideoData: null
    }
  },
  created() {
    this.getVideoDatas()
  },
  mounted() {
    window.addEventListener('resize', () => {
      // console.log("全屏状态:", screenfull.isFullscreen);
      if (!screenfull.isFullscreen) {
        this.stopRefresh = false
        this.initPlayer({
          data: this.originalVideo,
          pattern: this.pattern,
          pats: this.pats
        })
      }
    })
  },
  methods: {
    /**
     * 重新刷新监控容器高度
     */
    setRefresh() {
      const videosBox = document.getElementsByClassName('myPlayer-video')
      videosBox.forEach((item, index) => {
        const iframes = item.querySelector('iframe')
        iframes.width = '100%'
        iframes.height = '100%'
      })
    },
    /**
     * 获取监控数据
     */
    getVideoDatas() {
      this.loading = true
      getVideo().then((res) => {
        // eslint-disable-next-line no-unused-vars
        const { data, errorCode, message } = res
        this.videoList = data.items && data.items.length > 0 ? data.items : []
        if (hasVal(this.videoList)) {
          this.handleLocation(this.videoList[0], false)
          // 默认播放第一个模块的视频监控
          this.initPlayer({
            data: this.videoList[0].devices,
            pattern: this.pattern,
            pats: this.pats
          })
        } else {
          this.loading = false
        }
      })
    },
    /**
     * 初始化监控
     *  @param {Object} val 当前要播放的监控模块
     */
    initPlayer(val) {
      if (this.stopRefresh) return
      this.disposeVideo('myPlayer-video')
      let arr = []
      if (this.isFullScreen) {
        arr = val.data // 单条全屏播放数据
      } else {
        this.originalVideo = val.data // 存储当前-待播放监控模块-未分页前数据
        arr = this.originalVideo
      }
      this.pageTotal = (arr.length / this.pages.pageSize) * 10 // 总页数
      // 分页后的数据
      this.playVideos = paginationFun(
        this.pages.pageNo,
        this.pages.pageSize,
        arr
      )
      // 增加字段用于监控状态操作
      this.playVideos.forEach((h) => {
        h.delayState = false // 延时播放状态
        h.backState = false // 回放状态
        h.hdState = false // 标高清状态(默认选中标清)
        h.micState = false // 麦克风状态
        h.recording = false // 录制视频状态
        h.horn = false // 喇叭状态
      })
      console.log('---------【分页后的数据】---------', this.playVideos)
      this.$nextTick(() => {
        this.initElHenght(val.pattern, val.pats).then(() => {
          this.player = []
          setTimeout(() => {
            this.playVideos.forEach((item) => {
              const playerEl = new EZUIKit.EZUIKitPlayer({
                id: 'player_' + item.deviceId, // 视频容器ID
                accessToken: item.accessToken,
                url: item.url,
                plugin: ['talk'],
                width: 0,
                height: 0,
                fullScreenCallBack(e) {
                  console.log(e)
                }
              })
              this.player.push(playerEl)
            })
          }, 100)
          this.loading = false
        })
      })
    },
    /**
     * 初始化播放器盒子高度
     */
    initElHenght(tab, pat) {
      return new Promise((res, rej) => {
        // this.$nextTick(() => {
        var box = document.getElementsByClassName('video-content')[0]
        switch (tab) {
          case 0: // 2x2-每个盒子高度
            this.boxHeight = box.clientHeight / pat - pat * 18 // 18是video-item元素的上边距和边框所占用的像素
            break
          case 1: // 3x3-每个盒子高度
            this.boxHeight = box.clientHeight / pat - pat * 12.1 // 12.1是video-item元素的上边距和边框所占用的像素
            break
          case 2: // 全屏-每个盒子高度
            this.boxHeight = box.clientHeight / pat - pat * 35 // 35是video-item元素的上边距和边框所占用的像素
            break
        }
        res()
        // });
      })
    },
    /**
     * 销毁监控页面内容
     */
    disposeVideo(className) {
      if (this.originalVideo.length > 0) {
        const videos = document.getElementsByClassName(className)
        videos.forEach((item, index) => {
          item.innerHTML = ''
        })
      }
    },
    /**
     * 切换监控显示模式点击事件
     * @param {Number} index 索引
     */
    tabChange(index) {
      if (this.pattern === index) return
      this.handleLocation(this.originalVideo[0], true)
      this.pattern = index
      const num = this.pattern
      switch (num) {
        case 0: // 2x2
          this.$refs['leftBox'].clearAllSelect()
          this.currentPage = 1
          this.colNum = 12
          this.pats = 2
          this.pages.pageNo = 1
          this.pages.pageSize = 4
          this.isFullScreen = false
          this.stopRefresh = false
          this.initPlayer({
            data: this.originalVideo,
            pattern: num,
            pats: this.pats
          })
          break
        case 1: // 3x3
          this.$refs['leftBox'].clearAllSelect()
          this.currentPage = 1
          this.colNum = 8
          this.pages.pageNo = 1
          this.pats = 3
          this.pages.pageSize = 9
          this.isFullScreen = false
          this.stopRefresh = false
          this.initPlayer({
            data: this.originalVideo,
            pattern: num,
            pats: this.pats
          })
          break
        case 2: // 全屏
          this.colNum = 24
          this.pats = 1
          this.pages.pageNo = 1
          this.pages.pageSize = 1
          this.isFullScreen = true
          this.stopRefresh = false
          this.initPlayer({
            data: [this.originalVideo[0]],
            pattern: num,
            pats: this.pats
          })
          break
      }
    },
    /**
     * 切换视频监控模块展开和收起事件
     * @param {Object} e 监控模块数据
     */
    onFold(e) {
      if (e.data.selectArrow) return
      this.handleLocation(e.data, false)
      this.$refs['leftBox'].selectedBG = 0
      this.pattern = 0
      this.colNum = 12
      this.pats = 2
      this.pages.pageNo = 1
      this.pages.pageSize = 4
      this.isFullScreen = false
      this.stopRefresh = false
      if (e && e.data && e.data.devices) {
        this.initPlayer({
          data: e.data.devices,
          pattern: this.pattern,
          pats: this.pats
        })
      } else {
        this.loading = false
      }
    },
    /**
     * 在左侧列表上点击监控事件
     * @param {Object} e 监控数据
     */
    slectItem(e) {
      this.handleLocation(e.data, false)
      this.pattern = 2
      this.colNum = 24
      this.pats = 1
      this.pages.pageNo = 1
      this.pages.pageSize = 1
      this.isFullScreen = true
      this.stopRefresh = false
      this.initPlayer({
        data: [e.data],
        pattern: this.pattern,
        pats: this.pats
      })
    },
    /**
     * 分页器切换
     * @param {Number} p 当前切换到的页数
     */
    currentChange(p) {
      this.pages.pageNo = p
      this.stopRefresh = false
      this.initPlayer({
        data: this.originalVideo,
        pattern: this.pattern,
        pats: this.pats
      })
    },
    /**
     * 处理当前位置-(单条监控数据中有sn没有id,监控模块有id没有sn)
     *  @param {Object} e 当前切换到的页数
     */
    handleLocation(e, pop) {
      const videoDatas = this.videoList
      if (!videoDatas && videoDatas.length === 0) return
      this.currentLocation = []
      if (e && e.id) {
        this.currentLocation.push(
          videoDatas.filter((v) => v.id === e.id)[0].name
        )
      }
      if (e && e.sn) {
        for (let o = 0, len = videoDatas.length; o < len; o++) {
          if (videoDatas[o].devices && videoDatas[o].devices.length > 0) {
            for (
              let t = 0, _len = videoDatas[o].devices.length;
              t < _len;
              t++
            ) {
              if (videoDatas[o].devices[t].sn === e.sn) {
                this.currentLocation = pop
                  ? [videoDatas[o].name]
                  : [videoDatas[o].name, videoDatas[o].devices[t].name]
                break
              }
            }
          }
        }
      }
    },
    /**
     * 延时播放事件
     * @param {Number} index 操作的监控索引
     */
    onDelayed(index) {
      this.playVideos[index].delayState = !this.playVideos[index].delayState
      this.playVideos = [...this.playVideos]
      this.dialogVisibleTitle = `【延时播放】- ${this.playVideos[index].name}`
      this.status = false
      getDdelayedVideoUri({
        sn: this.playVideos[index].sn,
        channelNo: this.playVideos[index].channelNo
      })
        .then((res) => {
          const { data } = res
          this.videoUrl = data
          this.dialogVisible = true
        })
        .catch((err) => {
          setTimeout(() => {
            this.playVideos[index].delayState = false
            this.playVideos = [...this.playVideos]
          }, 500)
        })
    },
    /**
     * 回放事件
     * @param {Number} index 操作的监控索引
     */
    onPlayBack(index) {
      this.playVideos[index].backState = !this.playVideos[index].backState
      this.playVideos = [...this.playVideos]
      this.dialogVisibleTitle = `【回放】- ${this.playVideos[index].name}`
      this.backVideoData = this.playVideos[index]
      this.status = true
      this.dialogVisible = true
    },
    /**
     * 日期事件选择事件
     */
    dateChange(date) {
      this.disposeVideo('bakc-video')
      getVideoPlayBack({
        id: this.backVideoData.deviceId,
        begTime: date
      })
        .then((res) => {
          const { data } = res
          this.$nextTick(() => {
            setTimeout(() => {
              const playerEl = new EZUIKit.EZUIKitPlayer({
                id: 'bakc-video-box', // 视频容器ID
                accessToken: data.accessToken,
                url: data.url,
                width: 0,
                height: 0
              })
            }, 100)
          })
        })
        .catch((err) => {
          setTimeout(() => {
            this.backVideoData.backState = false
            this.playVideos = [...this.playVideos]
          }, 500)
        })
    },
    /**
     * 对话框关闭回调
     */
    close() {
      this.dateVal = null
      this.playVideos.forEach((e) => {
        e.delayState = false
        e.backState = false
      })
      this.playVideos = [...this.playVideos]
    },
    /**
     * 标/高清事件
     * @param {Number} index 操作的监控索引
     */
    onHd(index) {
      this.playVideos[index].hdState = !this.playVideos[index].hdState
      this.playVideos = [...this.playVideos]
      if (this.playVideos[index].hdState) {
        // 切换为高清
        this.player[index].stop()
        this.player[index].play({
          url: this.playVideos[index].hdUrl
        })
      } else {
        // 切换为标清
        this.player[index].stop()
        this.player[index].play({
          url: this.playVideos[index].url
        })
      }
    },
    /**
     * 监控图标操作点击事件
     *  @param {String} type 图标类型
     *  @param {Number} index 索引
     */
    handleClick(type, index) {
      switch (type) {
        case 'mic': // 麦克风
          this.playVideos[index].micState = !this.playVideos[index].micState
          this.playVideos = [...this.playVideos]
          if (this.playVideos[index].micState) {
            this.player[index].startTalk() // 开始说话
          } else {
            this.player[index].stopTalk() // 结束说话
          }
          break
        case 'recording': // 录制视频
          this.playVideos[index].recording = !this.playVideos[index].recording
          this.stopRefresh = true
          this.playVideos = [...this.playVideos]
          if (this.playVideos[index].recording) {
            this.player[index].startSave() // 开始录制
          } else {
            this.player[index].stopSave() // 结束录制
          }
          break
        case 'horn': // 喇叭
          this.playVideos[index].horn = !this.playVideos[index].horn
          this.playVideos = [...this.playVideos]
          if (this.playVideos[index].horn) {
            this.player[index].openSound() // 开启声音
          } else {
            this.player[index].closeSound() // 关闭声音
          }
          break
        case 'screenshot': // 截屏
          this.stopRefresh = true
          this.player[index].capturePicture() // 视频截图
          break
        case 'full': // 全屏
          this.stopRefresh = true
          this.player[index].cancelFullScreen() // 先取消全屏
          this.$nextTick(() => {
            // setTimeout(() => {
            this.player[index].fullScreen() // 开启全屏
            // }, 500);
          })
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-input__inner {
  border: none;
  background: rgba(4, 32, 89, 0.92) !important;
}
::v-deep input::-webkit-input-placeholder {
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #41a5d8 !important;
}
::v-deep .el-icon-time {
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #41a5d8 !important;
}
::v-deep .el-icon-circle-close {
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #41a5d8 !important;
}
::v-deep .el-dialog__header {
  background: rgba(4, 32, 89, 0.92) !important;
}
::v-deep .el-dialog__title {
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #41a5d8 !important;
}
::v-deep .el-icon-close {
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #41a5d8 !important;
}
::v-deep .el-button {
  border: rgba(4, 32, 89, 0) !important;
  background: rgba(4, 32, 89, 0) !important;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #41a5d8 !important;
}
::v-deep .el-dialog__body {
  background: rgba(4, 32, 89, 0.92) !important;
}
::v-deep .el-dialog__footer {
  background: rgba(4, 32, 89, 0.92) !important;
}
::v-deep .el-pagination button {
  width: 39px !important;
  height: 38px !important;
  background: rgba(255, 255, 255, 0.2) !important;
  border: 1px solid rgba(221, 221, 221, 0.2) !important;
  border-radius: 3px 3px 3px 3px !important;
  color: #999999 !important;
}
::v-deep {
  .el-pagination.is-background .el-pager li:not(.disabled) {
    width: 39px !important;
    height: 38px !important;
    background: rgba(255, 255, 255, 0.2) !important; // 进行修改未选中背景和字体
    border: 1px solid rgba(221, 221, 221, 0.2) !important;
    border-radius: 3px 3px 3px 3px !important;
    color: #999999 !important;
    line-height: 38px !important;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    width: 39px !important;
    height: 38px !important;
    border: 1px solid #1957a2 !important;
    background-color: #1957a2 !important; // 进行修改选中项背景和字体
    color: #fff !important;
    border-radius: 3px 3px 3px 3px !important;
    line-height: 38px !important;
  }
}
.content-box {
  // width: 100vw;
  // height: 100vh;
  // background: #042059;
  // position: fixed;
  // overflow-y: auto;
  // overflow-x: hidden;
  width: calc(100vw);
  height: calc(100vh);
  background: url("../../assets/image/body-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 100% 100%;
  .box-right {
    width: calc(100vw - (142px + 348px));
    height: calc(100vh - 102px);
    margin: 19px 84px 20px 58px;
    // border: $border;
    .right-title {
      font-size: 22px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #41a5d8;
    }
    .video-content {
      height: calc(100vh - 133px);
      .video-item {
        width: auto;
        // height: 100%;
        padding: 11px 14px;
        margin-top: 13px;
        background: rgba(11, 38, 92, 0.04);
        border: 1px solid rgba(15, 128, 205, 1);
        box-shadow: 0 0 15px #666666 inset;
        opacity: 0.8;
        position: relative;
        .video-footer {
          position: absolute;
          bottom: 11px;
          left: 14px;
          right: 14px;
          height: 50px;
          background: rgba(10, 10, 10, 0.92);
          .footer-title {
            margin-left: 10px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
          }
          .m-w-325 {
            max-width: 330px !important;
          }
          .m-w-110 {
            max-width: 110px !important;
          }
          .f-s-14 {
            font-size: 14px !important;
          }
          .footer-two {
            margin-left: 10px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            .m-l-15 {
              margin-left: 15px;
            }
          }
          .f-s-12 {
            font-size: 12px !important;
          }
          .footer-three {
            margin-left: 10px;
            margin-right: 10px;
            .mic-image {
              width: 24px;
              height: 21px;
            }
            .mic-image-ed {
              width: 24px;
              height: 35px;
            }
            .record-image {
              width: 24px;
              height: 24px;
            }
            .horn-image {
              width: 17px;
              height: 17px;
            }
            .screenshot-image {
              width: 18px;
              height: 18px;
            }
            .full-image {
              width: 15px;
              height: 15px;
            }
            .m-l-10 {
              margin-left: 10px;
            }
          }
        }
        .reco-load {
          position: absolute;
          top: -25px;
          left: 25px;
        }
      }
      .m-r-15 {
        margin-right: 15px !important;
      }
    }
  }
  .pagination-box {
    width: calc(100vw - (142px + 348px));
    height: 42px;
    margin-left: 58px;
  }
}
.myPlayer-video {
  width: calc(100%);
  height: calc(100%);
}

.video-back {
  width: 700px;
  height: 346px;
  padding: 11px 14px;
  margin-top: 13px;
  background: rgba(11, 38, 92, 0.04);
  border: 1px solid rgba(15, 128, 205, 1);
  box-shadow: 0 0 15px #666666 inset;
  opacity: 0.8;
  position: relative;
}
.bakc-video {
  width: 700px;
  height: 346px;
}
.t-s {
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #41a5d8 !important;
}
.date-picker {
  margin-bottom: 20px;
}
</style>
