<template>
  <div class="qhgq c-p" @click="onBack">
    <div class="qh-icon">
      <div class="xz">
        <el-image
          class="qh-image"
          :src="require('../assets/image/back.png')"
          :fit="'cover'"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    /**
     * 跳转页面事件
     * @param {Number} path 不同数字
     */
    openPath(path) {
      switch (path) {
        case 0:
          console.log('点击了项目中心')
          break
        case 1:
          this.$router.push('/monitor')
          break
        case 2:
          console.log('点击了机械与设备')
          break
      }
    },
    /**
     * 返回事件
     */
    onBack() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
.qhgq {
  position: fixed;
  right: 15px;
  bottom: 5px;
  width: 98px;
  height: 98px;
  background: url("../assets/image/qhgq.png") no-repeat center top;
  background-size: cover;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.qh-icon {
  margin-top: 4px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-image: linear-gradient(#00ffff, #00b7ee, #0068b7);
  padding: 15px;
  box-shadow: 0px 0px 10px rgba(0, 255, 255, 0.5);
}
.qh-image {
  width: 40px;
  height: 40px;
  opacity: 0.8;
  //   animation: turn 3s linear infinite;
}
@keyframes turn {
  0% {
    -webkit-transform: rotate(0deg);
  }

  25% {
    -webkit-transform: rotate(90deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
  }

  75% {
    -webkit-transform: rotate(270deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}
</style>
